<template>
  <div class="app-container">
    <el-form :inline="true">
      <el-form-item>
        <el-button size="small" type="primary" @click="back">返回</el-button>
      </el-form-item>
    </el-form>
    <div class="detail-container">
      <el-form :inline="true">
        <el-form-item label="交易编号："> {{ historyData.tradeNo }} </el-form-item>
      </el-form>
      <el-form :inline="true">
        <el-form-item label="说明：">
          在{{ parseTime(historyData.tradeTime) }} {{ type }}{{ historyData.tradeAmount }}</el-form-item
        >
      </el-form>
      <el-form :inline="true">
        <el-form-item label="变更金额：">
          {{ $route.query.isIncome == 1 ? '+￥' + historyData.tradeAmount : '-￥' + historyData.tradeAmount }}
        </el-form-item>
      </el-form>
      <el-form :inline="true">
        <el-form-item label="账户余额："> ￥{{ historyData.remainingAmount }} </el-form-item>
      </el-form>
      <el-form :inline="true">
        <el-form-item label="支付时间："> {{ parseTime(historyData.tradeTime) }} </el-form-item>
      </el-form>
    </div>
    <div class="change">
      <div :class="['changeSelect', changeType === 'detail' && 'active']" @click="change('detail')">订单详情</div>
      <div :class="['changeSelect', changeType === 'statistics' && 'active']" @click="change('statistics')">
        款式统计
      </div>
    </div>
    <div v-if="changeType === 'detail'" class="detail-container" style="margin-top: 20px">
      <el-form :inline="true">
        <el-form-item label="流水号："> {{ historyData.thirdpartTradeNo }} </el-form-item>
      </el-form>
      <el-form :inline="true">
        <el-form-item label="支付方式："> {{ payType(historyData.paymentType) }} </el-form-item>
      </el-form>
      <el-form :inline="true">
        <el-form-item label="应付金额：">
          <span v-if="historyData.totalAmount"> ￥{{ historyData.totalAmount }}</span>
        </el-form-item>
      </el-form>
      <el-form :inline="true">
        <el-form-item label="实付金额：">
          <span v-if="historyData.realAmount">￥{{ historyData.realAmount }}</span>
        </el-form-item>
      </el-form>
      <el-form :inline="true">
        <el-form-item label="支付状态："> {{ currentState(historyData.paymentStatus) }} </el-form-item>
      </el-form>
      <el-form :inline="true">
        <el-form-item label="支付时间："> {{ parseTime(historyData.tradeTime) }} </el-form-item>
      </el-form>
    </div>
    <div v-if="changeType === 'statistics'" class="detail-container" style="margin-top: 20px">
      <div class="styleStatistics">
        <div class="styleStatistics_product">产品总计：{{styleData.totalCount}}</div>
        <div>货款总额：{{styleData.totalAmount}}</div>
      </div>
      <CommonTable :selection="false" :cols="cols" :infoData="tableData"> </CommonTable>
    </div>
  </div>
</template>

<script>
import { styleCols as cols } from './cols'
import { parseTime } from '@/utils'
import { getDetails } from '@/api/balanceApi'
import { styleStatistical } from '@/api/financial/userCredit'

export default {
  data() {
    return {
      cols,
      tableData: [],
      changeType: 'detail',
      historyData: {},
      styleData:{}
    }
  },
  methods: {
    change(data) {
      this.changeType = data
    },
    parseTime,
    back() {
      this.$router.go(-1)
    }
  },
  computed: {
    type() {
      let cur
      if (!this.$route.query.tradeType) return
      let { tradeType } = this.$route.query
      tradeType = Number(tradeType)
      console.log('我被', tradeType)
      switch (tradeType) {
        case 1:
          cur = '充值'
          break
        case 2:
          cur = '提现'
          break
        case 3:
          cur = '订单支付'
          break
        case 4:
          cur = '退款'
          break
        case 5:
          cur = '手工调整余额'
          break
        case 6:
          cur = '提现审核拒绝退款'
          break
      }
      return cur
    },
    payType() {
      return (type) => {
        try {
          if (!type) return
          let cur
          switch (type) {
            case 1:
              cur = '支付宝'
              break
            case 2:
              cur = '微信'
              break
            case 3:
              cur = '银行卡'
              break
            case 4:
              cur = '余额付款'
              break
          }
          return cur
        } catch (error) {}
      }
    },
    currentState() {
      return (state) => {
        let cur
        switch (state) {
          case 0:
            cur = '未支付'
            break
          case 1:
            cur = '已支付'
            break
          case 2:
            cur = '关闭/取消'
            break
        }
        return cur
      }
    }
  },
  async created() {
    const { id } = this.$route.query
    try {
      const { code, detail } = await getDetails({ id })
      if (code != 0) return
      this.historyData = detail
    } catch (error) {}
    try {
      const { code, detail } = await styleStatistical({ id })
      if (code != 0) return
      this.styleData = detail || {}
      this.tableData = detail.styleStatisticalList
    } catch (error) {}
  }
}
</script>

<style lang="scss" scoped>
.app-container {
  padding: 20px 20px;
  .detail-container {
    border: 1px solid #ccc;
    padding: 10px;
  }
  .change {
    margin-top: 20px;
    display: flex;
    width: 150px;
    justify-content: space-between;
    .changeSelect {
      cursor: pointer;
    }
    .active {
      color: #6067e2;
    }
  }
  .styleStatistics {
    display: flex;
    margin: 10px 10px 20px 10px;
    .styleStatistics_product {
      margin-right: 20px;
    }
  }
}
</style>
